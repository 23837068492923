<template>
  <div>

    <h1 class="text-2xl font-semibold text-gray-900 mb-2">
     Prestaties overzicht
    </h1>

    <UISelectChauffeur active class="w-full max-w-sm" label v-model="apiData.user_id" />

    <div v-if="apiData.user_id > 0">
      <UILoading v-if="loading" />

      <UICalendar v-else :suffix-headers="['Week_Totaal']" v-model:month="apiData.month" v-model:year="apiData.year">
        <template v-slot:item-date="{ date }">
          <ol class="mt-2" v-if="getDateResultaat(date).totaal > 0">
            <li>
              <div class="group inline-flex bg-yellow-100 p-1 rounded w-full">
                <p class="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">Shift totalen</p>
                <div class="flex flex-col gap-0 font-bold">
                  <time datetime="2022-01-03T10:00" class="ml-3 hidden flex-none text-green-500 group-hover:text-green-600 xl:block">{{ millisecondsToHMSWritten(getDateResultaat(date).met) }}</time>
                  <template v-if="showTotalen">
                    <time datetime="2022-01-03T10:00" class="ml-3 hidden flex-none text-red-500 group-hover:text-red-600 xl:block">{{ millisecondsToHMSWritten(getDateResultaat(date).zonder) }}</time>
                    <hr>
                    <time datetime="2022-01-03T10:00" class="ml-3 hidden flex-none text-blue-500 group-hover:text-blue-600 xl:block">{{ millisecondsToHMSWritten(getDateResultaat(date).totaal) }}</time>
                  </template>
                </div>
              </div>
            </li>
          </ol>
        </template>

        <template v-slot:item-Week_Totaal="{ dates }">
          <ol class="mt-2">
            <li>
              <div class="group inline-flex bg-blue-100 p-1 rounded w-full">
                <div class="flex flex-col items-end gap-0 pb-2 font-bold">
                  <time datetime="2022-01-03T10:00" class="ml-3 hidden flex-none text-green-500 group-hover:text-green-600 xl:block">{{ millisecondsToHMSWritten(getWeekResultaat(dates).met) }}</time>
                  <template v-if="showTotalen">
                    <time datetime="2022-01-03T10:00" class="ml-3 hidden flex-none text-red-500 group-hover:text-red-600 xl:block">{{ millisecondsToHMSWritten(getWeekResultaat(dates).zonder) }}</time>
                    <hr>
                    <time datetime="2022-01-03T10:00" class="ml-3 hidden flex-none text-blue-500 group-hover:text-blue-600 xl:block">{{ millisecondsToHMSWritten(getWeekResultaat(dates).totaal) }}</time>
                  </template>
                </div>
              </div>
            </li>
          </ol>
        </template>
      </UICalendar>

      <div class="text-xs lg:text-sm italic card inline-flex flex-row items-center gap-3 mt-2" v-if="!loading">
        <b>Legende:</b>
        <ul class="flex flex-row gap-2">
          <li class="text-green-500"><b>GROEN:</b> Gewerkt</li>
          <li v-if="showTotalen" class="text-blue-500"><b>BLUE:</b> Totaal</li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import UILoading from '@/components/UI/Loading.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UICalendar from '@/components/UI/Calendar.vue'

import { millisecondsToHMSWritten } from '@/functions/formatDate'
import useGetApi from '@/hooks/useGetApi'

const today = new Date()

const store = useStore()
const showTotalen = computed(() => false && store.getters.hasMasterPermission)

const apiData = ref({
  month: today.getMonth() + 1,
  year: today.getFullYear(),
  user_id: null,
})

const { data, loading } = useGetApi('PRESTATIES_LIST', apiData, { skipMounted: true, watch: true })

const getDateResultaat = (date) => {
  const record = data.value?.[date] || {}
  return {
    totaal: (record.met || 0) + (record.zonder || 0),
    met: record.met || 0,
    zonder: record.zonder || 0,
  }
}

const getWeekResultaat = (dates) => {
  return dates.reduce((acc, date) => {
    const resultaat = getDateResultaat(date)
    return {
      totaal: acc.totaal + resultaat.totaal,
      met: acc.met + resultaat.met,
      zonder: acc.zonder + resultaat.zonder,
    }
  }, {
    totaal: 0,
    met: 0,
    zonder: 0,
  })
}
</script>
